import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import companies from './companies.json';
import { Card, Container, Row, Col, Nav, FormControl, InputGroup } from 'react-bootstrap';
import './Home.css';
import { CompanyType } from './types';

function Home() {
  const [search, setSearch] = useState('');
  
  const sortedCompanies: { [key: string]: CompanyType[] } = {
    '1-9': [],
    '#': [],
    ...'abcdefghijklmnopqrstuvwxyz'.split('').reduce((acc, letter) => {
      acc[letter] = companies.filter(company => company.name.toLowerCase().startsWith(letter));
      return acc;
    }, {} as Record<string, CompanyType[]>)
  };

  // populate '1-9' and '#' sections
  companies.forEach(company => {
    const firstChar = company.name.charAt(0);
    if (firstChar.match(/[1-9]/)) {
      sortedCompanies['1-9'].push(company);
    } else if (!firstChar.match(/[a-z]/i)) {
      sortedCompanies['#'].push(company);
    }
  });

  const navItems = Object.entries(sortedCompanies).filter(([_, companies]) => companies.length > 0);

  return (
    <div className="home">
      <Container>
        <Row className="justify-content-center mb-3">
          <Col sm={6} md={4} lg={3}>
            <InputGroup>
              <FormControl
                className="text-center"
                placeholder="Shop Name"
                aria-label="Company"
                aria-describedby="basic-addon1"
                onChange={e => setSearch(e.target.value.toLowerCase())}
                autoFocus
              />
              {/* <InputGroup.Text id="basic-addon1">Search</InputGroup.Text> */}
            </InputGroup>
          </Col>
        </Row>
        <Nav variant="pills" className="justify-content-center mb-3">
          {navItems.map(([letter, _], index) => (
            <Nav.Item key={index}>
              <Nav.Link href={`#${letter}`}>{letter.toUpperCase()}</Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
        
        {navItems.map(([letter, companies]) => {
          const filteredCompanies = companies.filter(company => company.name.toLowerCase().includes(search));
          return filteredCompanies.length > 0 && (
            <div id={letter} key={letter}>
              <h2>{letter.toUpperCase()}</h2>
              <Row>
                {filteredCompanies.map((company, index) => (
                  <Col lg={4} md={6} sm={12} key={index} className="mb-4">
                    <Card className="company-card h-100">
                      <Card.Body>
                        <Card.Title>{company.name}</Card.Title>
                        <Card.Text>
                          Wert: {company.coupon.value}
                        </Card.Text>
                        <Link to={`/${company.name.replace(/\s+/g, '_').toLowerCase()}`} className="stretched-link"/>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          );
        })}
      </Container>
    </div>
  );
}

export default Home;
