import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';
import Home from './Home';
import Company from './Company';
import './App.css';

function App() {
    return (
      <Router>
        <Navbar className="nav-color" expand="lg">
          <Navbar.Brand className="navbar-brand" href="/">
            <img
              src="/logos/logo_transparent_long.png"
              width="300"
              className="d-inline-block align-top logo-padding"
              alt="Logo"
            />
            <div className="slogan">
                <span className="first-letter">C</span>oupon Showcase, Ihr Ziel für <span className="highlight">erstklassige</span>, <span className="highlight">handverlesene</span> Gutscheine und Rabatte, um Ihnen das beste <span className="highlight">Sparerlebnis</span> zu bieten!
            </div>
          </Navbar.Brand>
        </Navbar>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:companyId" element={<Company />} />
        </Routes>
      </Router>
    );
  }

export default App;
