import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, Container, Alert } from 'react-bootstrap';
import companies from './companies.json';
import './Company.css';
import { CompanyType } from './types';
import ReactGA from 'react-ga';

function Company() {
  const { companyId } = useParams<{ companyId: string }>();
  const [couponCode, setCouponCode] = useState<string | undefined>('');
  const [couponLink, setCouponLink] = useState<string | undefined>('');
  const [couponValue, setCouponValue] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyLink, setCompanyLink] = useState('');
  const [description, setDescription] = useState('');
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    const company: CompanyType | undefined = companies.find(
      company => company.name.replace(/\s+/g, '_').toLowerCase() === companyId
    );
    if (company) {
      setCompanyName(company.name);
      setCompanyLink(company.link);
      setCouponCode(company.coupon.code);
      setCouponLink(company.coupon.link);
      setCouponValue(company.coupon.value);
      setDescription(company.coupon.description);
    }
  }, [companyId]);

  const handleCopy = () => {
    if(couponCode) {
      navigator.clipboard.writeText(couponCode);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // reset after 2 seconds
    }
  };

  const handleFacebookShare = () => {
    const url = window.location.href;
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
    window.open(shareUrl, '_blank');
  };
  
  const handleTwitterShare = () => {
    const url = window.location.href;
    const shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=Get ${couponValue} at ${companyName}!`;
    window.open(shareUrl, '_blank');
  };
  
  const handleWhatsAppShare = () => {
    const url = window.location.href;
    const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent('Get ' + couponValue + ' at ' + companyName + '! ' + url)}`;
    window.open(shareUrl, '_blank');
  };
  
  const handleSmsShare = () => {
    const url = window.location.href;
    const body = `Get ${couponValue} at ${companyName}! ${url}`;
    window.open(`sms:?&body=${encodeURIComponent(body)}`, '_self');
  };

  const handleCopyLink = () => {
    const url = window.location.href;
    navigator.clipboard.writeText(`Get ${couponValue} at ${companyName}! ${url}`);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };
  

  const handleRedirect = () => {
    if(couponLink) {
      window.location.href = couponLink;
    }
  }

  return (
    <div className="App">
      <Container className="p-3">
        <Card className="text-center">
          <Card.Header as="h1">Get {couponValue} at <a href={companyLink} target="_blank" rel="noreferrer" style={{ color: 'black' }}>{companyName}</a>!</Card.Header>
          <Card.Body>
            {couponCode && (
              <div className="mb-3 justify-content-center coupon-container">
                <div className="coupon-code">
                  {couponCode}
                </div>
                <Button className="copy-btn" variant="outline-secondary" onClick={handleCopy}>Copy</Button>
              </div>
            )}
            {couponLink && (
              <div className="mb-3 justify-content-center coupon-container">
                <div className="coupon-code">
                  {couponLink}
                </div>
                <Button className="copy-btn" variant="outline-secondary" onClick={handleRedirect}>Go</Button>
              </div>
            )}
            {copied && <Alert variant="success">Copied to clipboard!</Alert>}
            <Card.Text>{description}</Card.Text>
            <div className="share-buttons">
              <img src="/icons/square-facebook.svg" alt="Facebook Share" onClick={handleFacebookShare} />
              <img src="/icons/square-twitter.svg" alt="Twitter Share" onClick={handleTwitterShare} />
              <img src="/icons/square-whatsapp.svg" alt="WhatsApp Share" onClick={handleWhatsAppShare} />
              <img src="/icons/square-envelope-solid.svg" alt="SMS Share" onClick={handleSmsShare} />
              <img src="/icons/square-share-nodes-solid.svg" alt="Copy Link" onClick={handleCopyLink} />
            </div>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

export default Company;
